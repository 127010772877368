let isPortrait = screen.orientation.type.startsWith('portrait');

const showScreenSize = () => {
    let wrapper = document.querySelector('#screen-sizer');
    if (wrapper) {
        wrapper.textContent = 'screen: ' + screen.width + ' ' + screen.height + ' '
            + screen.orientation.type + ' window: ' + window.innerWidth + ' ' + window.innerHeight;
    }
};

const showMinimalScreenMessage = () => {
    let minimalMessage = document.querySelector('#minimal-screen-message');
    if (minimalMessage) {
        minimalMessage.style.display = 'flex';
    }
};

const hideMinimalScreenMessage = () => {
    let minimalMessage = document.querySelector('#minimal-screen-message');
    if (minimalMessage) {
        minimalMessage.style.display = 'none';
    }
};

const showEnlargeScreenMessage = () => {
    let minimalMessage = document.querySelector('#enlarge-screen-message');
    if (minimalMessage) {
        minimalMessage.style.display = 'flex';
    }
};

const hideEnlargeScreenMessage = () => {
    let minimalMessage = document.querySelector('#enlarge-screen-message');
    if (minimalMessage) {
        minimalMessage.style.display = 'none';
    }
};

const onResizeHandler = (hideCallback, unhideCallback) => {
    // TODO CHECK CUSTOM alter the screen sizes as needed (and if needed)
    isPortrait = screen.orientation.type.startsWith('portrait');
    showScreenSize();
    if (isPortrait === true) {
        hideEnlargeScreenMessage();
        if (window.innerWidth < 275) {
            showMinimalScreenMessage();
            hideCallback();
        } else {
            hideMinimalScreenMessage();
            unhideCallback();
        }
    } else {
        hideMinimalScreenMessage();
        if (window.innerWidth < 290) {
            showEnlargeScreenMessage();
            hideCallback();
        } else {
            hideEnlargeScreenMessage();
            unhideCallback();
        }
    }
};

module.exports = { isPortrait, showScreenSize, onResizeHandler };