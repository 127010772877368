import * as PIXI from 'pixi.js';
import { TimelineMax } from '../../static/js/gsap/esm';
import * as ScreenSizer from './inc/screen_sizer';

const loader = PIXI.Loader.shared;

const loadIndexScreen = () => {
    console.log('> preloading all the assets ..');
    loader.onComplete.add(() => {
        assetsLoaded();
    });
    loader.load();
};

const assetsLoaded = () => {
    console.log('>> preloading completed OK.');
    let timeline = new TimelineMax();
    timeline
        .to('#loading-screen', 0.50, { autoAlpha: 1 })
        .to('#loading-screen', 1.00, { autoAlpha: 0 });
};

const hidePageContent = () => {
    document.getElementById('page-wrapper').style.display = 'none';
};

const unhidePageContent = () => {
    document.getElementById('page-wrapper').style.display = 'unset';
};

const resizePage = () => {
    // TODO .. resize all relevant content
};

loadIndexScreen();
ScreenSizer.onResizeHandler(hidePageContent, unhidePageContent);

window.addEventListener('resize', () => {
    ScreenSizer.onResizeHandler(hidePageContent, unhidePageContent);
    resizePage();
});
